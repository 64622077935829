import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../../../constants';
import { SinglePlanInteractions } from '../../../../types/SinglePlanFedops';

export function openPlanLayoutPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  isMobile?: boolean;
  isPlanList?: boolean;
}) {
  const { editorSDK, componentRef, flowAPI, isMobile, isPlanList } = params;
  flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenLayoutPanel);

  const getHeight = () => {
    if (isMobile) {
      return 181;
    }

    return 463;
  };

  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.layouts-panel.title'),
      url: getPanelUrl('Plan', 'LayoutPanel'),
      height: getHeight(),
      width: 288,
      componentRef,
      initialData: {
        componentRef,
        isEditorX: flowAPI.environment.isEditorX,
        isMobile,
        isPlanList,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
